/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/goldcoin.glb")
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.COIN_1.geometry}
        material={materials.Default_OBJ}
      />
    </group>
  )
}

useGLTF.preload("/goldcoin.glb")
