import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import circlecrown from "../../assets/images/home/circlecrown.svg"
import Countdown from "react-countdown"
import { Canvas } from "@react-three/fiber"
import goldencanonwhite from "../../assets/images/home/goldencanonwhite.svg"
import Model from "../3dmodels/model5"

import { Environment, Float } from "@react-three/drei"
import { Suspense } from "react"
const DesktopMenuStyles = styled.div`
  position: absolute;
  top: 101px;
  left: 0;
  right: 0;

  background: var(--mfgreen);
  /* background-image: url(${goldencanonwhite}); */
  background-size: 100% 100%;
  display: block;
  border-bottom: 1px solid black;
  transform: translateY(0%);
  max-height: 800px;
  transition: max-height 0.8s cubic-bezier(1, 0.7, 0, 1) -0.1s;
  z-index: 1;
  overflow: hidden;
  transform: translateY(0);
  &.closed {
    max-height: 0px;
    transform: translateY(-1px);
  }
  menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    position: relative;
    padding: 0 32px;
    z-index: 2;

    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 1.8em;
        list-style: none;
        text-transform: uppercase;
        line-height: 2;
        a {
          padding: 5px;

          &.active {
            background: var(--mfpurple);
          }
        }
      }
    }
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
const MenuCanvas = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
`

export default function DesktopMenu({ mainmenuOpen, setmainmenuOpen, open }) {
  const className = mainmenuOpen ? "open" : "closed"
  const handleMainMenuClick = event => {
    setmainmenuOpen(!mainmenuOpen)
  }

  return (
    <>
      <DesktopMenuStyles className={className}>
        <menu>
          <ul>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </li>
            <li className="b">
              <a
                href="https://shop.magneticfields.in/"
                activeClassName="active"
                onClick={event => handleMainMenuClick(event)}
              >
                Merchandise
              </a>
            </li>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/lineup" activeClassName="active">
                Lineup
              </Link>
            </li>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/stages" activeClassName="active">
                Stages
              </Link>
            </li>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/showcases" activeClassName="active">
                Showcases
              </Link>
            </li>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/info" activeClassName="active">
                Info & Faq
              </Link>
            </li>

            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/news" activeClassName="active">
                News & Updates
              </Link>
            </li>

            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/fieldlines" activeClassName="active">
                Fieldlines
              </Link>
            </li>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/magnetic-minutes" activeClassName="active">
                #magneticminutes
              </Link>
            </li>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <a href="mailto:info@magneticfields.in">Contact Us</a>
            </li>
          </ul>
        </menu>
      </DesktopMenuStyles>
    </>
  )
}
