import React from "react"
import styled from "styled-components"
// logos
import bud from "../../assets/images/brand-logos/bud.png"
import goethe from "../../assets/images/brand-logos/goethe.png"
import RA from "../../assets/images/brand-logos/RA.png"
import skrap from "../../assets/images/brand-logos/skrap.png"
import sol from "../../assets/images/brand-logos/sol.png"
import tatva from "../../assets/images/brand-logos/tatva.png"
import jameson from "../../assets/images/brand-logos/jameson.png"
import svami from "../../assets/images/brand-logos/svami.png"
import rayban from "../../assets/images/brand-logos/rayban.png"
import bumble from "../../assets/images/brand-logos/bumble.png"
import german from "../../assets/images/brand-logos/german.jpg"
import lufthansa from "../../assets/images/brand-logos/lufthansa.png"
import tb from "../../assets/images/brand-logos/travelbureau.png"
import pursue from "../../assets/images/brand-logos/pursue.png"
import prohelvetia from "../../assets/images/brand-logos/prohelvetia.png"
import kimirica from "../../assets/images/brand-logos/kimirica.png"
import lifeplugin from "../../assets/images/brand-logos/lifeplugin.png"
import morningfresh from "../../assets/images/brand-logos/morningfresh.png"
import platform from "../../assets/images/brand-logos/platform.png"
import corona from "../../assets/images/brand-logos/corona.png"
import sepoy from "../../assets/images/brand-logos/sepoy.png"
import vedica from "../../assets/images/brand-logos/vedica.png"
const LogoStyles = styled.div`
  margin: 32px 0;

  grid-column: 1 / span 24;
  margin-bottom: 150px;
  color: var(--gray);
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  justify-items: center;
  align-items: center;
  @media (min-width: 768px) {
    grid-column: 1 / span 24;
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1400px) {
    grid-column: 4 / span 18;
    grid-template-columns: repeat(6, 1fr);
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 30px;
    max-width: 120px;
  }
  padding-bottom: 100px;
  @media (min-width: 1420px) {
    padding-bottom: 0;
  }
`

export default function Motif1() {
  return (
    <>
      <LogoStyles>
        <div className="logo">
          <img src={jameson} alt="" />
        </div>
        <div className="logo">
          <img src={bud} alt="" />
        </div>

        <div className="logo">
          <img src={rayban} alt="" />
        </div>
        <div className="logo">
          <img src={corona} alt="" />
        </div>
        {/* <div className="logo">
          <img src={bumble} alt="" />
        </div> */}

        <div className="logo">
          <img src={pursue} alt="" />
        </div>

        <div className="logo">
          <img src={RA} alt="" />
        </div>
        <div className="logo">
          <img src={sepoy} alt="" />
        </div>
        <div className="logo">
          <img src={vedica} alt="" />
        </div>
        {/* <div className="logo">
          <img src={svami} alt="" />
        </div> */}

        {/* <div className="logo">
          <img src={goethe} alt="" />
        </div> */}
        <div className="logo">
          <img src={lifeplugin} alt="" />
        </div>

        <div className="logo">
          <img src={tatva} alt="" />
        </div>

        <div className="logo">
          <img src={skrap} alt="" />
        </div>
        <div className="logo">
          <img src={tb} alt="" />
        </div>
        <div className="logo">
          <img src={sol} alt="" />
        </div>

        <div className="logo">
          <img src={platform} alt="" />
        </div>
        {/* <div className="logo">
          <img src={sol} alt="" />
        </div> */}
        {/* <div className="logo">
          <img src={lufthansa} alt="" />
        </div>
        <div className="logo">
          <img src={german} alt="" />
        </div>
        <div className="logo">
          <img src={tb} alt="" />
        </div> */}
        {/* <div className="logo">
          <img src={prohelvetia} alt="" />
        </div>
        <div className="logo">
          <img src={kimirica} alt="" />
        </div>
        <div className="logo">
          <img src={morningfresh} alt="" />
        </div> */}
      </LogoStyles>
    </>
  )
}
