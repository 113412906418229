import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons"
const DatesStyled = styled.div`
  font-family: "IBM Regular", courier;
  font-size: 18px;
  margin-right: 30px;
  @media (min-width: 1380px) {
    margin-right: 40px;
  }
  display: none;
  @media (min-width: 1420px) {
    display: block;
  }
  text-transform: uppercase;
  text-align: right;
  span {
    display: inline;
    line-height: 1;
    margin: 4px 0;
    padding: 4px 0;
    background: var(--mfgreen);

    display: inline-block;
    &.yellow {
      background: var(--mfyellow);
    }
    &.blue {
      background: var(--mfblue);
    }
    &.pink {
      background: var(--mfpink);
    }

    &:nth-of-type(2n + 2) {
    }
    &:nth-of-type(2n + 1) {
    }
  }
`

export default function Dates({ colour, setColour }) {
  const loopArr = [
    "Alsisar Mahal, Rajasthan, India ",
    "28.3036° N, 75.2853° E  ",
    "Festival of Contemporary Music & Arts",
  ]

  //const [index, setIndex] = useState(0)

  /*useEffect(() => {
    const intervalDelayMilliseconds = loopArr[index].length * 500
    const interval = setInterval(() => {
      setIndex(prevIndex => {
        // reset index if current index is greater than array size
        return prevIndex + 1 < loopArr.length ? prevIndex + 1 : 0
      })
    }, 1500)

    return () => clearInterval(interval)
  })
*/
  return (
    <>
      <DatesStyled className="dates">
        <span className="green sb">
          &nbsp;&nbsp;December 9-11 2022&nbsp;&nbsp;<br></br>
        </span>
        <br></br>
        <span className="yellow sb ">
          &nbsp;&nbsp;Alsisar Mahal, Rajasthan, India&nbsp;&nbsp; <br></br>
        </span>
      </DatesStyled>
    </>
  )
}
