import React, { useState, useEffect } from "react"

import Logo from "../../assets/images/header/logo.svg"
import MobileLogo from "../../assets/images/header/mobilelogo.svg"
import MenuText from "../../assets/images/header/menutext.svg"
import MenuEye from "../../assets/images/header/menueye.svg"
import bars from "../../assets/images/icons/bars-solid.svg"
import styled from "styled-components"
import Button from "../button/Button"
import MenuButton from "../button/MenuButton"
import { Link } from "gatsby"
import DesktopMenu from "../DesktopMenu/DesktopMenu"
import Dates from "./Dates"
import Socials from "./Socials"
import ButtonExternal from "../button/ButtonExternal"
const HeaderWrapStyles = styled.div`
  width: 100%;
  z-index: 100;

  &.fixed {
    position: fixed !important;
    top: 0;
    .home {
      .slide {
        transition: all 0.1s ease-in;
        transform: translateX(0);
      }
    }
  }
  &.notfixed {
    position: absolute;
    .home {
      .slide {
        transition: all 0.1s ease-in;

        transform: translateX(-230px);
        @media (min-width: 990px) {
          transform: translateX(-230px);
        }
      }
    }
  }
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`
const HeaderStyles = styled.div`
  display: flex;
  height: 100px;
  left: 0;
  right: 0;
  background: var(--mfwhite);
  z-index: 10;
  position: relative;
  align-items: center;
  padding: 0px 15px;

  @media (min-width: 1330px) {
    padding: 0px 15px;
  }
  border-bottom: 1px solid var(--mfblack);
  border-top: 1px solid var(--mfblack);
`
const HeaderMobile = styled.div``

const MobileLogoStyles = styled.div`
  position: absolute;
  top: 40px;
  left: 15px;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`
const MobileLogoImageStyles = styled.div`
  width: 75px;
  max-width: 75px;
`

const LogoStyles = styled.div`
  width: 180px;
  max-width: 180px;
  display: flex;
  align-items: center;
  a {
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
  }
  @media (min-width: 1024px) {
    width: 210px;
    max-width: 210px;
  }
`
const MenuStyles = styled.div`
  margin-left: 40px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-block;
      padding-right: 16px;
    }
  }
  display: none;
  @media (min-width: 990px) {
    display: block;
  }
`

const RightMenuStyles = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  display: none;
  @media (min-width: 720px) {
    display: flex;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      padding: 0;
      margin: 0;
      list-style: none;

      padding-right: 16px;
    }
  }
`

export default function Header({ location, colour, setColour }) {
  //scroll pos , and set scroll of header fixed
  const [scroll, setScroll] = useState("notfixed")
  const [mainmenuOpen, setmainmenuOpen] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 109) {
        setScroll("fixed")
      } else {
        setScroll("notfixed")
      }
      // setScroll(window.scrollY > 176)
    })
  }, [])

  var className = mainmenuOpen ? "open" : "closed"

  const handleMainMenuClick = event => {
    setmainmenuOpen(!mainmenuOpen)

    className = mainmenuOpen ? "open" : "closed"
  }

  var menuText = mainmenuOpen ? "Close" : "Menu"

  //check if home page
  const isHomepage = location.pathname === "/" ? "home" : "nothome"

  return (
    <>
      <MobileLogoStyles>
        <MobileLogoImageStyles>
          <Link to="/">
            <img src={MobileLogo} alt="" />
          </Link>
        </MobileLogoImageStyles>
      </MobileLogoStyles>
      <HeaderWrapStyles className={scroll === "fixed" ? "fixed" : "notfixed"}>
        <HeaderStyles className={isHomepage}>
          <LogoStyles className="noslide">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </LogoStyles>
          <MenuStyles className="noslide">
            <ul>
              <li
                onMouseEnter={() => {
                  setColour("green")
                }}
                onMouseLeave={() => {
                  setColour("white")
                }}
              >
                <Button
                  bgColour="hovgreen"
                  linktext="Lineup"
                  linkto="/lineup"
                ></Button>
              </li>
              <li
                onMouseEnter={() => {
                  setColour("pink")
                }}
                onMouseLeave={() => {
                  setColour("white")
                }}
              >
                <Button
                  bgColour="hovpink"
                  linktext="Showcases"
                  linkto="/showcases"
                ></Button>
              </li>
              <li
                onMouseEnter={() => {
                  setColour("blue")
                }}
                onMouseLeave={() => {
                  setColour("white")
                }}
              >
                <Button
                  bgColour="hovblue"
                  linktext="News"
                  linkto="/news"
                ></Button>
              </li>

              {/* <li
                onMouseEnter={() => {
                  setColour("blue")
                }}
                onMouseLeave={() => {
                  setColour("white")
                }}
              >
                <Button
                  bgColour="hovblue"
                  linktext="Lineup"
                  linkto="/lineup"
                ></Button>
              </li> */}

              <li
                onMouseEnter={() => {
                  setColour("yellow")
                }}
                onMouseLeave={() => {
                  setColour("white")
                }}
              >
                <Button
                  bgColour="hovyellow"
                  linktext="Info"
                  linkto="/info"
                ></Button>
              </li>
              {/* <li
                onMouseEnter={() => {
                  setColour("purple")
                }}
                onMouseLeave={() => {
                  setColour("white")
                }}
              >
                <ButtonExternal
                  bgColour="hovpurple"
                  linktext="Merch"
                  linkto="https://shop.magneticfields.in/"
                ></ButtonExternal>
              </li> */}
            </ul>
          </MenuStyles>
          <RightMenuStyles>
            <Socials />
            <ul>
              <li onClick={event => handleMainMenuClick(event)}>
                <MenuButton>
                  {menuText}
                  <img
                    width="18px"
                    src={bars}
                    alt=""
                    style={{ marginLeft: "8px" }}
                  />
                </MenuButton>
              </li>
            </ul>
          </RightMenuStyles>
        </HeaderStyles>
        <DesktopMenu
          open={className}
          mainmenuOpen={mainmenuOpen}
          setmainmenuOpen={setmainmenuOpen}
        />
      </HeaderWrapStyles>
    </>
  )
}
