import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, StaticQuery } from "gatsby"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

// home experiences
const MemorySliderStyled = styled.div`
  .slideWrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .gatsby-image-wrapper {
      height: 100%;
    }
    .fullheight {
      height: 100%;
      width: 100%;
    }
  }
`

const SlideCard = styled.div`
  .SlideCardWrap {
  }
`

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export default function MemorySwiper() {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
            relativeDirectory: { eq: "assets/images/2022/best" }
          }
        ) {
          edges {
            node {
              id
              name
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  width: 1400
                  breakpoints: [750, 1080, 1366, 1920]
                )
              }
            }
          }
        }
      }
    `
  )

  const params = {
    freeMode: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    spaceBetween: 0,
    allowTouchMove: true,
    autoHeight: false,
  }

  return (
    <MemorySliderStyled>
      <div className="slideWrap">
        <Swiper className="fullheight" {...params}>
          {images.edges.map(image => (
            <div key={image.node.id}>
              <SwiperSlide className="fullheight" key={image.node.id}>
                <GatsbyImage
                  key={image.node.id}
                  alt={image.node.name}
                  image={getImage(image.node)}
                  objectFit="cover"
                ></GatsbyImage>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </MemorySliderStyled>
  )
}
