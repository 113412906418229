import React, { useState, useEffect } from "react"
import banner from "../../assets/images/header/banner.png"
import styled from "styled-components"
import Marquee from "react-fast-marquee"

const BannerStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index:-1;
  @media (min-width: 768px) {
    height: 108px;
  }
  overflow: hidden;
  color: var(--mfwhite);
  &.white {
    color: var(--mfwhite);
  }
  &.pink {
    color: var(--mfpink);
  }
  &.green {
    color: var(--mfgreen);
  }
  &.yellow {
    color: var(--mfyellow);
  }
  &.blue {
    color: var(--mfblue);
  }
  &.purple {
    color: var(--mfpurple);
  }
  .row {
    height: 22px;
    @media (min-width: 768px) {
      height: 32px;
    }
    transform: translateY(-65px);
    &.row1 {
      opacity: 0.2;
      @media (min-width: 768px) {
        opacity: 0.2;
      }
    }
    &.row2 {
      opacity: 0.5;
      @media (min-width: 768px) {
        opacity: 0.5;
      }
    }
    &.row3 {
      opacity: 0.6;
      @media (min-width: 768px) {
        opacity: 0.6;
      }
      &.row3 {
      opacity: .9;
      @media (min-width: 768px) {
        opacity: .8;
      }
    }
  }
  .marqueeContainer {
  }
  .scrollRows {
    display: flex;
    flex-direction: column;
    transform: translateY(0px);
    @media (min-width: 768px) {
      transform: translateY(8px);
    }
  }
  .scrollText {
    font-size: 64px;

    @media (min-width: 768px) {
      font-size: 80px;
    }
    font-family: "Magnetic Fields","impact", "helvetica";
    text-transform: uppercase;
    height: 80px;
    @media (min-width: 768px) {
      height: 260px;
    }
  }
  img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

export default function Banner({ colour, setColour }) {
  //colour it
  const className = colour

  const marqueeSettings = {
    gradient: false,
    speed: 20,
    className: "marqueeContainer",
  }
  const marqueeSettings2 = {
    gradient: false,
    speed: 24,
    direction: "left",
    className: "marqueeContainer",
  }
  const marqueeSettings3 = {
    gradient: false,
    speed: 28,
    direction: "left",
    className: "marqueeContainer",
  }
  const marqueeSettings4 = {
    gradient: false,
    speed: 32,
    direction: "left",
    className: "marqueeContainer",
  }
  return (
    <>
      <BannerStyles className={className}>
        <div className="scrollRows">
          <div className="row row1">
            {" "}
            <Marquee {...marqueeSettings}>
              <div className="scrollText">
                magnetic fields festival magnetic fields festival magnetic
                fields festival &nbsp;
              </div>
            </Marquee>
          </div>

          <div className="row row2">
            {" "}
            <Marquee {...marqueeSettings2}>
              <div className="scrollText">
                magnetic fields festival magnetic fields festival magnetic
                fields festival &nbsp;
              </div>
            </Marquee>
          </div>

          <div className="row row3">
            {" "}
            <Marquee {...marqueeSettings3}>
              <div className="scrollText">
                magnetic fields festival magnetic fields festival magnetic
                fields festival &nbsp;
              </div>
            </Marquee>
          </div>
          <div className="row row4">
            {" "}
            <Marquee {...marqueeSettings4}>
              <div className="scrollText">
                magnetic fields festival magnetic fields festival magnetic
                fields festival &nbsp;
              </div>
            </Marquee>
          </div>
        </div>
      </BannerStyles>
    </>
  )
}
