import React from "react"
import styled from "styled-components"
import Mailchimp from "react-mailchimp-form"
import FooterLogos from "../footerlogos/Footerlogos"
import dots from "../../assets/images/dots/dots.svg"
const SponsorsStyles = styled.div`
  background: var(--mfblack);
  padding: 32px;
  grid-column-start: 1;
  grid-column-end: 25;
  padding-bottom: 100px;
  position: relative;
  @media (min-width: 768px) {
    padding-bottom: 0;
    grid-column-start: 1;
    grid-column-end: 25;
  }
  @media (min-width: 1420px) {
    padding-bottom: 0;
    grid-column-start: 1;
    grid-column-end: 25;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .sponsorsWrap {
    display: flex;
    align-items: center;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 25;
    h1 {
      color: white;
      margin: 0;
    }
  }
  .border {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    content: "";
    /*
    border-style: dotted;
    border-color: white;
    border-image-source: url(${dots});
    border-image-slice: 33% 33%;
    border-image-repeat: round;
    */
    /* border-image-width: 8px; */
    /* border-width: 8px; */
    /* border-top-width: 0px; */
    /* border-width: 7px 7px 7px 7px; */
  }
`

export default function Sponsors() {
  return (
    <SponsorsStyles className="">
      <div className="border"></div>
      <div className="SponsorsWrap">
        <div className="title">
          <h1>Support From</h1>
        </div>

        <FooterLogos />
      </div>
    </SponsorsStyles>
  )
}
