import React, { useState, useEffect } from "react"

import Logo from "../../assets/images/header/logo.svg"
import MobileLogo from "../../assets/images/header/mobilelogo.svg"
import MenuText from "../../assets/images/header/menutext.svg"
import MenuEye from "../../assets/images/header/menueye.svg"
import close from "../../assets/images/header/close.svg"

import styled from "styled-components"
import Button from "../button/Button"
import { Link } from "gatsby"

const MobileMenuButtonStyles = styled.div`
  position: fixed;
  top: 40px;
  right: 15px;
  width: 75px;
  height: 75px;
  background: var(--mfblack);
  z-index: 101;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  .closeIcon {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.open {
      display: block;
      width: 18px;
    }
    &.close {
      display: none;
      width: 18px;
    }
  }
  .eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    max-height: none;
    transition: all 0.2s ease-in;
    &.blink {
      height: 1px;
    }
    &:active {
      height: 1px;
    }
    &.open {
      display: none;
      width: 18px;
    }
    &.close {
      display: block;
      width: 18px;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: rotating 10s linear infinite;
    -moz-animation: rotating 10s linear infinite;
    -ms-animation: rotating 10s linear infinite;
    -o-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
  }
`

export default function MobileMenuButton({ menuOpen, setmenuOpen }) {
  //scroll pos , and set scroll of header fixed
  const className = menuOpen ? "open" : "closed"
  const [blink, setBlink] = useState("eye")

  useEffect(() => {
    var interval = setInterval(() => {
      setBlink("blink")
      setTimeout(function () {
        setBlink("eye")
      }, 500)
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const handleMenuClick = event => {
    // event.preventDefault();
    // Using the parent component's state to keep track of the menu
    // alert('232');
    setmenuOpen(!menuOpen)
  }

  return (
    <MobileMenuButtonStyles onClick={event => handleMenuClick(event)}>
      <img
        className={
          blink === "blink" ? " eye blink " + className : "eye " + className
        }
        src={MenuEye}
        alt=""
      />
      <img className={className + " closeIcon"} src={close} alt="" />
      <div className="rotating">
        <img src={MenuText} alt="" />
      </div>
    </MobileMenuButtonStyles>
  )
}
