import React, { useState, useEffect } from "react"
import Header from "./Header/Header"
import "normalize.css"
import Footer from "./Footer/Footer"
import Typography from "../styles/Typography"
import Banner from "./Header/Banner"
import MobileMenuButton from "./Header/MobileMenuButton"
import MobileMenu from "./MobileMenu/MobileMenu"

export default function Layout({ children, location, path }) {
  // Colour
  const [colour, setColour] = useState("white")
  const [menuOpen, setmenuOpen] = useState(false)

  const handleMenuClick = event => {
    setmenuOpen("closed")
  }

  return (
    <>
      <div className="cutoff">
        <Banner colour={colour} setColour={setColour} />
        <Header colour={colour} setColour={setColour} location={location} />

        <MobileMenuButton menuOpen={menuOpen} setmenuOpen={setmenuOpen} />
        <MobileMenu menuOpen={menuOpen} setmenuOpen={setmenuOpen} />
        {children}
        <Footer />
      </div>
    </>
  )
}
