import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons"

const SocialsStyled = styled.div`
  font-family: "IBM Regular", courier;
  font-size: 18px;
  margin-right: 30px;
  @media (min-width: 1380px) {
    margin-right: 40px;
  }
  display: none;
  @media (min-width: 1420px) {
    display: block;
  }
  ul {
    margin: 0;
    display: inline-flex;
    padding: 0;
    align-items: space-between;
    li {
      background: var(--mfwhite);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      padding: 0;
      margin: 0px 8px;

      a {
        font-size: 1.2em;
        color: black;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`

export default function Socials() {
  const loopArr = [
    "Alsisar Mahal, Rajasthan, India ",
    "28.3036° N, 75.2853° E  ",
    "Festival of Contemporary Music & Arts",
  ]

  return (
    <>
      <SocialsStyled>
        <div className="socials">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/magneticfieldsfestival/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/magneticfieldsfestival/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/magneticfields" target="_blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                href="https://soundcloud.com/magneticfieldsfestival"
                target="_blank"
              >
                <FontAwesomeIcon icon={faSoundcloud} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC-fZp9wjxFL00hhtUDn3e7Q"
                target="_blank"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
      </SocialsStyled>
    </>
  )
}
