import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ButtonStyles = styled.div`
  font-size: 18px;
  padding: 0px 10px;
  min-width: 100px;
  background: var(--mfblack);
  color: var(--mfwhite);
  display: inline-block;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1) 0s;
  font-family: "IBM Regular", courier;
  text-transform: uppercase;
  height: 36px;
  border: 1px solid var(--mfblack);
  white-space: nowrap;
  cursor: pointer;
  &.pink {
    background: var(--mfpink);
    color: var(--mfblack);
    &:hover {
      filter: brightness(95%);
      background: var(--mfpink);
      color: var(--mfblack);
    }
  }

  &:hover {
    background: #525151;
    color: white;
  }

  &.white {
  }

  &.hovpink {
    background: var(--mfpink);
    color: var(--mfblack);
    &:hover {
      background: var(--mfpink);
    }
  }

  &.hovyellow {
    background: var(--mfyellow);
    color: var(--mfblack);
    &:hover {
      background: var(--mfyellow);
      color: var(--mfblack);
      filter: brightness(95%);
    }
  }

  &.hovgreen {
    background: var(--mfgreen);
    color: var(--mfblack);
    &:hover {
      background: var(--mfgreen);
      color: var(--mfblack);
      filter: brightness(95%);
    }
  }

  &.hovblue {
    background: var(--mfblue);
    color: var(--mfblack);
    &:hover {
      background: var(--mfblue);
      color: var(--mfblack);
      filter: brightness(95%);
    }
  }
`

export default function Button({ linktext, children }) {
  return (
    <>
      <ButtonStyles className={"button "}>
        {linktext}
        {children}
      </ButtonStyles>
    </>
  )
}
