import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Newsletter from "../newsletter/Newsletter"
import Sponsors from "./Sponsors"
import Memory from "./Memory"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"

const FooterStyles = styled.div`
  grid-column-start: 1;
  grid-column-end: 17;

  border-top: 1px solid black;
  overflow: hidden;
  background: white;
  position: relative;
  .waves {
    transform: translateY(-15px);
  }
`

export default function Footer() {
  return (
    <>
      <FooterStyles className="grid24">
        <Newsletter />
        {/* <Memory /> */}
        <Sponsors />
      </FooterStyles>
    </>
  )
}
