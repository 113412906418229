import React from "react"
import styled from "styled-components"
import Mailchimp from "react-mailchimp-form"
import MemorySwiper from "./MemorySwiper"

const MemoryStyles = styled.div`
  grid-column-start: 1;
  grid-column-end: 25;
  background: var(--mfgreen);
  min-height: 450px;
  position: relative;
  @media (min-width: 768px) {
    grid-column-start: 13;
    grid-column-end: 25;
  }
  @media (min-width: 1420px) {
    grid-column-start: 13;
    grid-column-end: 25;
  }
`

export default function Memory() {
  return (
    <MemoryStyles>
      <MemorySwiper />
    </MemoryStyles>
  )
}
